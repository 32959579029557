import React, { createContext, useState, useContext } from 'react';

// Create a context with default value as empty string
export const UserContext = createContext("");

export const UserProvider = ({ children }) =>
{
    const [userRole, setUserRole] = useState("");

    return (
        <UserContext.Provider value={{ userRole, setUserRole }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook for easier use
export const useUserRole = () =>
{
    const context = useContext(UserContext);
    if (!context)
    {
        throw new Error("useUserRole must be used within a UserProvider");
    }
    return context;
};