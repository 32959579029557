import http from './httpService';

const apiUrl = process.env.REACT_APP_UserApi_Url;

function userUrl(id)
{
  return `${apiUrl}/${id}`
}

export function register(user)
{
  return http.post(apiUrl, {
    id: user.id,
    name: user.name,
    email: user.email,
    role: user.role,
    active: user.active
  })
}

export function getUsers()
{
  return http.get(apiUrl)
}

export function getUser(userId)
{
  return http.get(userUrl(userId))
}

export function saveUser(user)
{
  if (user.id)
  {
    const body = { ...user }

    return http.put(userUrl(user.id), body)
  }
  return http.post(apiUrl, user)
}

export function deleteUser(userId)
{
  return http.delete(userUrl(userId))
}
