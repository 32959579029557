import React from 'react'
import Joi from 'joi-browser'
import Form from './Common/form'
import { register } from './../services/userService'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '../authConfig'
import '../Styles/Form.css'

const msalInstance = new PublicClientApplication(msalConfig)

class RegisterForm extends Form {
  state = {
    data: {
      id: '',
      name: '',
      email: '',
      role: 'student',
      active: 1,
    },
    users: [],
    errors: {},
    idFound: false,
    accessToken: null,
  }

  schema = {
    id: Joi.string().required().label('ID'),
    name: Joi.string().required().label('Name'),
    email: Joi.string().required().email().label('Email'),
    role: Joi.string().valid('student', 'admin').required().label('Role'),
    active: Joi.number().integer().required().min(0).max(1).label('Active'),
  }

  async componentDidMount() {
    try {
      const accounts = await msalInstance.getAllAccounts()
      const silentRequest = {
        scopes: ['User.ReadBasic.All'],
        account: accounts[0],
      }
      const response = await msalInstance.acquireTokenSilent(silentRequest)
      this.setState({ accessToken: response.accessToken })
    } catch (ex) {
      console.error(ex)
    }
  }

  async getUserIdByEmail(email) {
    const graphEndpoint = `https://graph.microsoft.com/v1.0/users/${email}`

    let { accessToken } = this.state
    if (!accessToken) {
      try {
        const accounts = await msalInstance.getAllAccounts()
        const silentRequest = {
          scopes: ['User.ReadBasic.All'],
          account: accounts[0],
        }
        const response = await msalInstance.acquireTokenSilent(silentRequest)
        accessToken = response.accessToken
        this.setState({ accessToken })
      } catch (ex) {
        console.error(ex)
        throw new Error('Access token could not be obtained')
      }
    }

    let retryCount = 0
    while (retryCount < 2) {
      const response = await fetch(graphEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (response.status === 401) {
        try {
          const accounts = await msalInstance.getAllAccounts()
          const popupRequest = {
            scopes: ['User.ReadBasic.All'],
            account: accounts[0],
          }
          const response = await msalInstance.acquireTokenPopup(popupRequest)
          accessToken = response.accessToken
          this.setState({ accessToken })
        } catch (ex) {
          console.error(ex)
          throw new Error('Access token is invalid or has expired')
        }
      } else {
        const data = await response.json()
        const userId = data.id
        const displayName = data.displayName
        console.log(`User ID for ${email}: ${userId}`)
        console.log(`Name for ${email}: ${displayName}`)
        return { userId, displayName }
      }

      retryCount++
    }

    throw new Error('Access token is invalid or has expired')
  }

  doSubmit = async () => {
    try {
      const { id } = this.state.data
      if (id !== undefined && id !== null) {
        const response = await register(this.state.data)
        window.location = '/'
      } else {
        throw new Error('ID is required.')
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.username = ex.response.data
        this.setState({ errors })
      }
    }
  }

  handleFindClick = async (e) => {
    e.preventDefault()
    const { data } = this.state
    const { userId, displayName } = await this.getUserIdByEmail(data.email)
    const newData = { ...data, id: userId || '', name: displayName || '' }
    this.setState({ data: newData, idFound: true })
  }

  render() {
    return (
      <div className="container">
        <h1>New User Form</h1>
        {this.renderInput('email', 'Email Address')}
        <button
          onClick={this.handleFindClick.bind(this)}
          className="btn btn-primary"
        >
          Find Azure ID
        </button>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('id', 'ID', 'text', true, this.state.data.id)}
          {this.renderInput(
            'name',
            'Name',
            'text',
            false,
            this.state.data.name,
          )}
          {this.renderInput('email', 'Email')}
          {this.renderSelect('role', 'Role', [
            { value: 'student', label: 'Student' },
            { value: 'admin', label: 'Admin' },
          ])}
          {this.renderButton('Save')}
        </form>
      </div>
    )
  }
}

export default RegisterForm
