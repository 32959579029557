import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import Select from './select';


class Form extends Component {
    state = {
        data: {},
        errors: {}
    };


    validate = () => {
        const options = { abortEarly: false }
        const { error } = Joi.validate(this.state.data, this.schema, options);

        if (!error) {
            return null;
        }

        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }

        return errors;
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // console.log()

        const errors = this.validate();
        this.setState({ errors: errors || {} });

        if (errors) {
            return;
        }

        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
        // console.log(input);
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);

        if (errorMessage) {
            errors[input.name] = errorMessage;
        } else {
            delete errors[input.name];
        }

        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data, errors });
        // console.log(this.state.data);
    };

    renderButton = (label) => {
        return (
            <button
                disabled={this.validate()}
                className="btn btn-primary"
            >
                {label}
            </button>
        );
    }

    renderSelect(name, label, options) {
        const { data, errors } = this.state;
    
        return (
          <div className='form-group'>
            <label htmlFor={name}>{label}</label>
            <select
              name={name}
              id={name}
              value={data[name]}
              onChange={this.handleChange}
              className='form-control'
            >
              <option value='' />
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors[name] && (
              <div className='alert alert-danger'>{errors[name]}</div>
            )}
          </div>
        );
    }
    
    
    renderInput = (name, label, type = 'text') => {
        const { data, errors } = this.state;

        // console.log(data[name]);
 
        return (
            <Input
                name={name}
                value={name === 'id' ? data.id : data[name]}
                label={label}
                type={type}
                onChange={this.handleChange}
                error={errors[name]}
                disabled={(name === 'id' || name === 'active')}
                hidden={false}
            />
        
        );
    } 
}


export default Form;
