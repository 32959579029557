import React, { useEffect } from 'react';
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

const Login = () =>
{
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() =>
    {
        // Check if the user is already authenticated; if not, initiate the login process
        if (!isAuthenticated)
        {
            instance.loginRedirect(loginRequest).catch(e =>
            {
                console.log(e);
            });
        }
    }, [isAuthenticated, instance]);

    // Render a loading message while the authentication is in progress
    if (!isAuthenticated)
    {
        return <h2 style={{ textAlign: 'center' }}>Logging in...</h2>;
    }

};

export default Login;
