import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import axios from 'axios'
import RegisterForm from './registerForm'


function BatchRegister()
{
  const [users, setUsers] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [notAddedUsers, setNotAddedUsers] = useState([])

  const handleFileInputChange = (event) =>
  {
    setSelectedFile(event.target.files[0])
  }

  const register = (user) =>
  {
    return axios.post(process.env.REACT_APP_UserApi_Url, {
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
      active: user.active,
    })
  }

  const checkUserExists = async (email) =>
  {
    if (!email)
    {
      return false
    }
    try
    {
      const response = await axios.get(
        process.env.REACT_APP_UserApi_Url + `?email=${email}`,
      )
      return response.data.find((user) => user.email === email) !== undefined
    } catch (error)
    {
      return false
    }
  }

  const handleFileUpload = async () =>
  {
    if (!selectedFile)
    {
      return
    }
    const reader = new FileReader()
    reader.onload = async (e) =>
    {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      // Extract name and email columns
      const nameColumnIndex = rows[0].indexOf('Name')
      const emailColumnIndex = rows[0].indexOf('Email')
      const userRows = rows
        .slice(1)
        .filter((row) => row[nameColumnIndex] && row[emailColumnIndex])

      // Lookup Azure IDs by email
      const emailList = userRows.map((row) => row[emailColumnIndex])
      const registerForm = new RegisterForm()
      const azureIds = await Promise.all(
        emailList.map(async (email) =>
        {
          const { userId } = await registerForm.getUserIdByEmail(email)
          return userId
        }),
      )

      // Add users to SQL database
      const usersToAdd = userRows.map((row, index) => ({
        id: azureIds[index],
        name: row[nameColumnIndex],
        email: row[emailColumnIndex],
        role: 'student',
        active: 1,
      }))

      const addedUsers = []
      const notAddedUsers = []

      for (let i = 0; i < usersToAdd.length; i++)
      {
        const userExists = await checkUserExists(
          usersToAdd[i].email.toLowerCase(),
        )
        if (userExists)
        {
          notAddedUsers.push({
            ...usersToAdd[i],
            reason: 'Already in the database',
          })
        } else
        {
          try
          {
            const response = await register(usersToAdd[i])
            addedUsers.push(response.data)
          } catch (error)
          {
            notAddedUsers.push({ ...usersToAdd[i], reason: error.message })
          }
        }
      }

      setUsers(addedUsers)
      setNotAddedUsers(notAddedUsers)
    }
    reader.readAsArrayBuffer(selectedFile)
  }

  const handleFileDownload = () =>
  {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([
      { Name: '', Email: '' }
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileName = 'BatchRegisterTemplate.xlsx';
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    link.click();
  }

  return (
    <div>
      <h1>Batch Register Users</h1>
      <h4>Upload a CSV file with Name and Email columns</h4>
      <input type="file" onChange={handleFileInputChange} />
      <button
        className="btn btn-primary"
        style={{ marginBottom: 15 }}
        onClick={handleFileUpload}
      >
        Upload
      </button>
      <button
        className="btn btn-primary"
        style={{ marginBottom: 15, marginLeft: 15 }}
        onClick={handleFileDownload}
      >
        Download Template
      </button>
      <h3>
        {users.length > 0 ? `${users.length} users added successfully` : null}
      </h3>
      {notAddedUsers.length > 0 ? (
        <div>
          <h3>{notAddedUsers.length > 0 ? `${notAddedUsers.length} users not added` : null}</h3>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {notAddedUsers.map((user) => (
                <tr key={user.email}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  )
}

export default BatchRegister
