import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import UsersTable from '../Components/usersTable';
import Pagination from '../Components/Common/pagination';
import SearchBox from '../Components/searchBox';
import { getUsers, deleteUser } from '../services/userService';
import { paginate } from '../utils/paginate';
import _ from 'lodash';

const Users = () =>
{
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [sortColumn, setSortColumn] = useState({ path: 'name', order: 'asc' });

  useEffect(() =>
  {
    async function fetchData()
    {
      try
      {
        const usersResponse = await getUsers();
        setUsers(usersResponse.data);
      } catch (error)
      {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data');
      }
    }
    fetchData();
  }, []);

  const handleDelete = async (user) =>
  {
    const originalUsers = users;
    const updatedUsers = originalUsers.filter(u => u.id !== user.id);
    setUsers(updatedUsers);
    try
    {
      await deleteUser(user.id);
    } catch (ex)
    {
      if (ex.response && ex.response.status === 404)
      {
        toast.error('This user has already been deleted');
      }
      setUsers(originalUsers);
    }
  };

  const handleSort = (sortCol) =>
  {
    setSortColumn(sortCol);
  };

  const handlePageChange = (page) =>
  {
    setCurrentPage(page);
  };

  const handleSearch = (query) =>
  {
    setSearchQuery(query);
    setSelectedRole(null);
    setCurrentPage(1);
  };

  const getPagedData = () =>
  {
    let filtered = users;
    if (searchQuery)
    {
      filtered = users.filter(
        u => u.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          u.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedRole && selectedRole.id)
    {
      filtered = users.filter(u => u.role.id === selectedRole.id);
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginatedUsers = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: paginatedUsers };
  };

  const { totalCount, data: paginatedUsers } = getPagedData();

  return (
    <div className="row">
      <div className="col d-flex justify-content-center">
        <h4 style={{ marginTop: 20 }}>
          Showing {totalCount} users in the database
        </h4>
      </div>
      <div style={{ marginBottom: 15, width: '500px' }}>
        <SearchBox value={searchQuery} onChange={e => handleSearch(e)} />
      </div>
      <UsersTable
        users={paginatedUsers}
        sortColumn={sortColumn}
        onDelete={handleDelete}
        onSort={handleSort}
      />
      <div style={{ width: '2000px', display: 'inline-block' }}>
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <div className="col-3"></div>
    </div>
  );
};

export default Users;
