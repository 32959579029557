import React from 'react'

const Unauthorized = () =>
{
    return (
        <div>
            <br />
            <br />
            <h1>401 Unauthorized</h1>
            <p>You are not authorized to access this resource.</p>
            <p>Contact Program Support or LabMX if you think this is a mistake.</p>
        </div>
    );
}

export default Unauthorized;