import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./Screens/Home";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { UserProvider } from './services/userContext';
// import useWindowDimensions from "./useWindowDimensions.js";
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
// added cache busting

const App = () =>
{
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const { height, width } = useWindowDimensions();
  const isProduction = process.env.NODE_ENV === 'production';
  const { version } = packageJson;

  // useEffect(() =>
  // {
  //   if (height > 900 && width >= 600)
  //   {
  //     setMobile(false);
  //   } else if (height < 900 && width <= 600)
  //   {
  //     setMobile(true);
  //   }
  // }, []);

  useEffect(() =>
  {
    if (!isAuthenticated)
    {
      instance
        .ssoSilent({
          scopes: ["User.Read", "User.ReadBasic.All"],
        })
        .then((response) =>
        {
          instance.setActiveAccount(response.account);
        });
    }
  },);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      Loading={false}
      metaFileDirectory={'./var/www/react-frontends/userportal.uamishub.com/html/meta.json'} //If public assets are hosted somewhere other than root on your server.
    >
      <React.Fragment>
        <UserProvider>{/* This is the custom user context provider to store roles globally within the app instead of using session storage*/}
          <Home />
        </UserProvider>
      </React.Fragment>
    </CacheBuster >
  );
};

export default App;
