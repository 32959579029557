import React, { Component } from 'react';
import Table from './Common/table';
import { Link } from 'react-router-dom';
import '../Styles/UsersTable.css'

class UsersTable extends Component
{
  columns = [
    {
      path: 'id',
      label: 'ID',
      content: (user) => (
        <Link to={`/users/${user.id}`}>{user.id}</Link>
      )
    },
    { path: 'name', label: 'Name' },
    { path: 'email', label: 'Email' },
    { path: 'role', label: 'Role' },
    { path: 'active', label: 'Active' },
    {
      key: 'delete',
      content: (user) => (
        <button
          onClick={() => this.props.onDelete(user)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      ),
    },
  ]
  render()
  {
    const { users, onSort, sortColumn } = this.props
    return (
      <Table
        className="users-table"
        columns={this.columns}
        data={users}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    )
  }
}

export default UsersTable
