import React from 'react';
import
{
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Users from '../Pages/Users';
import RegisterForm from '../Components/registerForm';
import NotFound from '../Components/notFound';
import Unauthorized from '../Components/Unauthorized';
import BatchRegister from '../Components/BatchRegister';
import ManageRoles from '../Pages/ManageRoles';
import Login from '../Screens/Login';
import ProtectedRoutes from '../Components/Common/protectedRoutes';
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/App.css';
import '../Styles/Home.css';
import
{
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from '@azure/msal-react';
import PageTemplate from '../Components/Common/PageTemplate';
import Roles from '../Pages/Roles';

const GetHooks = () =>
{
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return { params, navigate, location };
};

const RegisterFormWrapper = (props) =>
{
  return <RegisterForm {...{ ...props, hooks: GetHooks() }} />;
};

const BatchRegisterWrapper = (props) =>
{
  return <BatchRegister {...{ ...props, hooks: GetHooks() }} />;
};

const Home = () =>
{
  return (
    <div className="wrapper">
      <AuthenticatedTemplate>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            {/* Protected routes go here */}
            <Route path="/" element={<PageTemplate />} >
              <Route index element={<Users />} />
              <Route path="/users" element={<Users />} />
              <Route path="/roles" element={<ManageRoles />} />
              <Route path="users/:id" element={<Roles />} />
              <Route path="users/new" element={<RegisterFormWrapper />} />
              <Route path="users/batch-upload" element={<BatchRegisterWrapper />} />
            </Route>
          </Route>
          {/* Public routes go here */}
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Home;
