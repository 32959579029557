import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCurrentUser } from '../../services/authService';

const ProtectedRoutes = () =>
{
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() =>
  {
    async function fetchData()
    {
      const user = await getCurrentUser();
      setCurrentUser(user);
    }
    fetchData();
  }, []);

  if (!currentUser)
  {
    // Render a loading spinner or some other indication that the user is being fetched
    return <div>Loading...</div>;
  }

  // const isUserAdmin = currentUser.roles === 'admin'; // old hardcoded version where "role" was a string on user Table, leaving for reference
  const isUserAdmin = currentUser.roles.some(role => role.roleName === 'Administrator');
  if (!isUserAdmin)
  {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;

};

export default ProtectedRoutes;