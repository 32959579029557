import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Outlet, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../../Styles/Sidebar.css";

function PageTemplate(props)
{
    const { instance } = useMsal();
    const [expanded, setExpanded] = useState(false);

    const handleLogout = () =>
    {
        instance.logoutRedirect().catch(e => console.error(e));
    };


    const renderNavLinks = () =>
    {
        const commonLinks = [
            { to: "/", label: "Home" },
            { to: "/roles", label: "Manage Roles" },
            { to: "/users/new", label: "New User" },
            { to: "/users/batch-upload", label: "Batch Upload" },
        ];


        const linksToRender = commonLinks

        return linksToRender.map(link => (
            <h5 key={link.to} id="navH5">
                <Nav.Link>
                    <Link to={link.to} id="navC" onClick={() => setExpanded(false)}>
                        {link.label}
                    </Link>
                </Nav.Link>
            </h5>
        ));
    };

    return (
        <>
            <div className="maindash">{props.children}</div>
            <Navbar expand="lg" expanded={expanded}>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    style={{ backgroundColor: "white", marginLeft: "1%", border: "0", marginTop: "0" }}
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="basic-navbar-nav" style={{ zIndex: 2 }}>
                    <Nav style={{ marginTop: "1%", backgroundColor: "maroon", width: "100%" }}>
                        {renderNavLinks()}
                        <h5 id="navH5">
                            <Nav.Link style={{ color: "white" }}>
                                <a id="navC" style={{ alignContent: "end" }} onClick={handleLogout}>
                                    Logout
                                </a>
                            </Nav.Link>
                        </h5>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Outlet />
        </>
    );
}

export default PageTemplate;
