import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const Pagination = (props) => {
  const { itemsCount, pageSize, currentPage, onPageChange } = props
  const pagesCount = Math.ceil(itemsCount / pageSize)

  if (pagesCount === 1) {
    return null
  }

  const pages = _.range(1, pagesCount + 1)
  let startIndex = Math.max(0, currentPage - 4)
  let endIndex = Math.min(startIndex + 10, pagesCount)
  if (endIndex - startIndex < 10) {
    startIndex = Math.max(0, endIndex - 10)
  }
  const firstIndex = 1
  const lastIndex = pagesCount

  return (
    <nav>
      <ul className="pagination">
        {currentPage > 1 && (
          <li key="first" className="page-item">
            <a className="page-link" onClick={() => onPageChange(firstIndex)}>
              First
            </a>
          </li>
        )}
        {startIndex > 0 && (
          <li key="prev" className="page-item">
            <a
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
            >
              Previous
            </a>
          </li>
        )}
        {pages.slice(startIndex, endIndex).map((page) => (
          <li
            key={page}
            className={page === currentPage ? 'page-item active' : 'page-item'}
          >
            <a className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </a>
          </li>
        ))}
        {endIndex < pagesCount && (
          <li key="next" className="page-item">
            <a
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
            >
              Next
            </a>
          </li>
        )}
        {currentPage < pagesCount && (
          <li key="last" className="page-item">
            <a className="page-link" onClick={() => onPageChange(lastIndex)}>
              Last
            </a>
          </li>
        )}
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default Pagination;